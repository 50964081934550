<template>
  <div>
    <div class="d-flex align-center">
      <header-label
        :label="$t('packing.header')"
        prepend-icon="$packingHeader" />
      <div class="mb-3">
        <v-icon color="primary">
          mdi-chevron-left
        </v-icon>
      </div>
      <span class="primary--text mb-3">
        {{ $t('shopsetting.header') }}
      </span>
    </div>
    <v-card flat>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="9">
            <packing-toggle
              v-model="setting.enablePackedStage"
              :text="$t('packing.enablePackedStage')"
              :state="state.enablePackedStage"
              text-icon="$packageTitle"
              @input="checkToggle('enablePackedStage')">
            </packing-toggle>
          </v-col>
        </v-row>
        <v-row
          class="mt-3"
          no-gutters>
          <v-col
            cols="9"
            class="pl-12">
            <packing-toggle
              v-model="setting.checkProductBeforeConfirmPack"
              :text="$t('packing.checkProductBeforeConfirmPack')"
              :state="state.checkProductBeforeConfirmPack"
              :disable="setting.enablePackedStage"
              @input="checkToggle('checkProductBeforeConfirmPack')">
            </packing-toggle>
          </v-col>
        </v-row>
        <v-row
          class="mt-3"
          no-gutters>
          <v-col
            cols="9"
            class="pl-12">
            <packing-toggle
              v-model="setting.orderCheckItemAutoClick"
              :text="$t('packing.orderCheckItemAutoClick')"
              :state="state.orderCheckItemAutoClick"
              :disable="checkConfirm"
              class="ml-12"
              @input="checkToggle('orderCheckItemAutoClick')">
            </packing-toggle>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PackingToggle from '@/views/ShopSetting/Pages/Packing/components/PackingToggle'
import { GET_SETTING_PACKING } from '@/resources/graphql/query/Setting'
import { UPDATE_SETTING } from '@/resources/graphql'

export default {
  name: 'Packing',
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_PACKING
    })
  },
  components: {
    PackingToggle
  },
  data () {
    return {
      setting: {},
      state: {
        enablePackedStage: 'ready',
        checkProductBeforeConfirmPack: 'ready',
        orderCheckItemAutoClick: 'ready'
      }
    }
  },
  computed: {
    checkConfirm () {
      return this.setting.enablePackedStage && this.setting.checkProductBeforeConfirmPack
    }
  },
  methods: {
    checkToggle (field) {
      if (field === 'enablePackedStage' && !this.setting[field]) {
        if (this.setting.checkProductBeforeConfirmPack) {
          this.setting.checkProductBeforeConfirmPack = false
          this.submit('checkProductBeforeConfirmPack')
        }
        if (this.setting.orderCheckItemAutoClick) {
          this.setting.orderCheckItemAutoClick = false
          this.submit('orderCheckItemAutoClick')
        }
        this.submit('enablePackedStage')
      } else if (field === 'checkProductBeforeConfirmPack' && !this.setting[field]) {
        if (this.setting.orderCheckItemAutoClick) {
          this.setting.orderCheckItemAutoClick = false
          this.submit('orderCheckItemAutoClick')
        }
        this.submit('checkProductBeforeConfirmPack')
      } else {
        this.submit(field)
      }
    },
    async submit (field) {
      this.state[field] = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.setting[field]
          }
        }
      }).catch(() => {
        this.state[field] = 'error'
      })
      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state[field] = 'error'
        } else {
          this.state[field] = 'success'
        }
      }
    }
  }
}
</script>
